<template>
  <v-card
    outlined
    class="gift-card d-flex flex-column flex-md-row align-center justify-center justify-md-space-between px-3 w-100"
    :id="`git-card-${product.slug}`"
    ref="giftCard"
    :key="componentKey"
  >
    <v-row no-gutters class="gift-card-wrapper">
      <v-col cols="12" md="2" class="d-flex justify-center align-center mt-2"
        ><img
          :src="product.mediaURL"
          onerror="this.onerror=null;this.src='/no-icon.png'"
          class="product-img align-self-center"
          :alt="'Immagine' + product.name"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
      /></v-col>
      <v-col
        cols="12"
        md="3"
        class="description d-flex flex-column justify-center align-md-start align-center"
      >
        <span class="stamps font-weight-bold" v-if="showBollini">
          <span class="stamps-number">
            {{ product.productInfos["NO_BOLL"] }}</span
          >
          <span> {{ $t("product.rewards.bollini") }}</span>
        </span>
        <span class="name">
          <strong>{{ product.name }}</strong>
        </span>
        <div v-if="item && item.cartItemInfo && item.cartItemInfo.size">
          {{ item.cartItemInfo.size }}
        </div>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-center align-center">
        <ProductPrice v-if="product.priceDisplay >= 0.01" :product="product"
      /></v-col>
      <v-col
        cols="12"
        md="3"
        class="gift-actions d-flex flex-column flex-md-row justify-center justify-md-end align-center"
      >
        <v-card-actions
          class="w-100"
          v-if="product.available > 0"
          @click.stop.prevent
          :key="componentKey"
        >
          <template v-if="item != null">
            <v-btn
              x-large
              block
              color="primary lighten-1"
              outlined
              @click="handleRemove(item)"
            >
              {{ $t("product.rewards.removeFromCart") }}
            </v-btn>
          </template>
          <template v-else>
            <v-row no-gutters class="justify-center align-center">
              <v-col cols="12" class="gift-actions pa-1"
                ><v-btn
                  class="add-to-cart"
                  color="primary lighten-1"
                  depressed
                  x-large
                  block
                  @click="handleAdd()"
                >
                  {{ $t("product.rewards.addToCart") }}
                </v-btn></v-col
              >
            </v-row>
          </template>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.gift-card {
  &-wrapper {
    gap: 10px;
    .product-price:not(.is-list) .no-promo-price {
      font-size: 3rem;
    }
  }
  .product-img {
    max-width: 100%;
  }
  .product-price {
    width: 100%;
    .product-descr-weight,
    .old-price {
      display: none;
    }
  }
  .stamps {
    color: var(--v-primary-base);
    font-size: 20px;
    &-number {
      font-size: 2rem;
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .gift-card-wrapper {
    gap: 15px;
    .product-price {
      width: 60%;
    }
  }
}
</style>
<script>
import ProductPrice from "./ProductPrice.vue";
//import ProductQty from "./ProductQty.vue";

//import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

//import cloneDeep from "lodash/cloneDeep";
import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";

export default {
  name: "GiftCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined },
    horizontal: { type: Boolean, required: false, default: true },
    minAmount: { type: Number, required: false, default: 30.0 },
    selectedOption: { type: String, required: false }
  },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  mixins: [productMixin],
  components: { ProductPrice },
  data() {
    return {
      // selectOptionsArray: [],
      selectedOptions: {},
      componentKey: 0,
      key: 0,
      item: null,
      selectedOptionData: null
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),

    showBollini() {
      return (
        this.product.productInfos?.PRODUCT_NATURE_ID === "8" &&
        this.product.productInfos?.NO_BOLL?.length > 0
      );
    },
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId != 10002
      );
    }
  },
  methods: {
    async handleAdd() {
      let payload = {
        product: this.product,
        quantity: 1
      };

      let res = await this.addProduct(payload);
      if (res) {
        let filteredItems = this.cart.cartItems.filter(
          item => item.product.productId == this.product.productId
        );
        this.item = filteredItems[0];
        console.log("ggg", this.item);
      }
    },
    handleRemove(elem) {
      this.removeItem(elem);
      this.selectedOptionData = null;
      this.item = null;
      // this.componentKey++;
    }
  },
  mounted() {
    this.product.productInfos.PRODUCT_NATURE_ID === "8";
    this.product.productInfos.NO_BOLL === "40";
    if (this.cart.cartItems) {
      let filteredItems = this.cart.cartItems.filter(
        item =>
          item.product.productInfos.PRODUCT_NATURE_ID &&
          item.product.productInfos.PRODUCT_NATURE_ID === "8"
      );
      if (filteredItems.length == 0) {
        //   this.selectedOptionData = null;

        this.item = null;
      } else {
        let filteredItems = this.cart.cartItems.filter(
          item => item.product.productId == this.product.productId
        );
        //  this.selectedOptionData = this.selectedOption;
        this.item = filteredItems[0];
      }
    }
  },
  created() {
    global.EventBus.$on("removeGift", item => {
      this.handleRemove(item);
    });
    global.EventBus.$on("removeAllGift", items => {
      for (var i = 0; i < items.length; i++) {
        if (
          items[i].product.productInfos &&
          items[i].product.productInfos.PRODUCT_NATURE_ID &&
          items[i].product.productInfos.PRODUCT_NATURE_ID === "8"
        ) {
          this.handleRemove(items[i]);
        }
      }
    });
  },
  watch: {
    "cart.cartItems": function(items) {
      let filteredItems = items.filter(
        item =>
          item.product.productInfos.PRODUCT_NATURE_ID &&
          item.product.productInfos.PRODUCT_NATURE_ID === "8"
      );
      if (filteredItems.length == 0) {
        // this.selectedOptionData = null;
        this.item = null;
      }
    }
  }
};
</script>
